
.providers {
    width: "100%";
    height: "100%";
    display: block;
}
.buttonStyle {
       display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        user-select: none;
        border-radius: 4px;
        color:#5b5b5b;
        background-color:#00dbad !important;
}
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #5b5b5b;
    background-color:#00dbad !important;
}
.nav-item{
    color: #5b5b5b;
}
.table tbody td i{
    display: flex;
    justify-content: center;
    align-items: center;
}
.form{
    margin-top: 20px;    
}

.react-datetime-picker__wrapper{
    border-width: 0 !important;
    border:none !important
}
.existing{
    color:grey
}
a{
    text-decoration: none;
    color: inherit;
}
.tableDiv {
    overflow: auto;
    display: block;
   }
.error{
    color: red;
} 
.selected{
    border: 1px solid #ddd;
    background: #eee;
    font-size: 18px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    cursor: default;
    border-radius: 2px;
}  
.remove{
    color: #aaa;
    margin-left: 10px;
    cursor: pointer;
}